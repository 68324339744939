<template>
  <iframe
    v-if="url!==true"
    id="unnax"
    :src="url"
    name="unnax"
    frameborder="0"
    :width="width"
    :height="height"
    class="pz-large-margin"
    allow="geolocation; microphone; camera;"
    @load="iframeLoaded"
  />
  <div
    v-else
    class="pic-404"
  >
    <img
      class="pic-404__parent"
      src="@/assets/images/exitoso.png"
      alt="success"
    >
  </div>
</template>

<script>
import iFrameResize from 'iframe-resizer/js/iframeResizer'

export default {
  name: "PzUnnaxIframe",

  props: {
    url: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      height: '690px',
      width: '550px'
    }
  },

  methods: {
    onResize(messageData) {
      this.height = messageData.height + 'px';
      this.width = messageData.width + 'px';
    },

    iframeLoaded() {
      iFrameResize({
        log: false,
        checkOrigin: false,
        scrolling: 'no',
        onMessage: this.onMessage,
        onResized: this.onResize
      }, '#unnax');
    }
  }
}
</script>

<style scoped>

</style>