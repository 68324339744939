<template>
  <el-col
    v-if="esmobil"
    class="pz-large-margin text-lg-center"
  >
      <el-col
          class="row justify-content-center"
          :span="24"
      >
          <pz-button
              v-if="continuar"
              key="send"
              type="primary"
              :button-name="$t('continue')"
              @click="handleSend"
          />
      </el-col>
    <iframe
      id="naat"
      frameborder="0"
      width="100%"
      :height="height"
      class="pz-large-margin"
      :src="frame_route"
      sandbox="allow-forms allow-modals allow-popups allow-same-origin allow-scripts"
      allow="geolocation; microphone; camera; fullscreen"
    />
  </el-col>

  <el-col
    v-else
    class="pz-large-margin text-lg-center"
  >
    <pz-title>
      <span slot="title">
        Escanear Codigo QR para continuar el proceso en su Mobile <br>
      </span>
    </pz-title>
    <el-row :gutter="20">
      <el-col
        :xl="{span: 24}"
        :lg="{span: 24}"
        :md="{span: 24}"
        :sm="{span: 24}"
        :xs="{span: 24}"
      >
        <vue-qrcode
          :value="url"
          :options="{ width: 200 }"
        />
      </el-col>
    </el-row>
  </el-col>
</template>

<script>

import {mapGetters} from "vuex";
import { isMobile} from '@/commons/utils/functions';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import {setNaat} from "@/routes/api/views/vales";
import PzButton from "@/commons/forms/Buttons/PzButton";

export default {
    name: "PzNaat",
    components: {VueQrcode,PzButton},
    props: {
        route: {
            require: true,
            default: null,
            type: [String, URL]
        },
        redirect: {
            require: true,
            default: 'Prestamos',
            type: [String]
        },
    },
    data() {
        return {
            frame_route: this.route,
            url: window.location.href,
            height: '600px',
            watch: {
                route(value) {
                    this.frame_route = value;
                },
                frame_route(value) {
                    console.log('change form route');
                    console.log(value);
                },

            },
            continuar: false,
        }
    },
    computed: {
        ...mapGetters(['alias']),
        esmobil(){
            return isMobile()
        }
    },

    created() {
        window.addEventListener("message", (event) => {
            if (event.data) {
                if (event.data.event === 'PROCESS_STARTED') {
                    console.log('proceso iniciado');
                } else if (event.data.event == 'PROCESS_COMPLETED') {

                    setNaat(this.$route.params.alias, this.$route.params.loan).finally(() => {
                        this.continuar= true;
                        this.loading = false;
                        this.handleSend();
                    });
                    console.log('proceso terminado');
                } else if (event.data.event == 'PROCESS_ENDED') {

                    setNaat(this.$route.params.alias, this.$route.params.loan).finally(() => {
                        this.continuar= true;
                        this.loading = false;
                        this.handleSend();
                    });
                    console.log('proceso terminado');
                } else if (event.data.event == 'SIGNATURE_COMPLETED') {

                    setNaat(this.$route.params.alias, this.$route.params.loan).finally(() => {
                        this.continuar= true;
                        this.loading = false;
                        this.handleSend();
                    });
                    console.log('proceso de firma completo');
                } else if (event.data.event === 'RELOAD_PROCESS') {
                    console.log('reinicia el proceso');
                }
            } else {
                return;
            }
        }, false);
    },
    methods: {
        handleSend() {
            this.$store.dispatch('loader/up', {trigger: this.$options.name});
            this.$router.push({name: 'FinFirma'});
            this.$store.dispatch('loader/down', {trigger: this.$options.name});
        }
    },

}
</script>
